.input-error-message {
  bottom: calc(100% + 10px);
  right: 0;
  text-align: right;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  position: absolute;
}

a {
  transition: 0s !important;
}
.upload_image > input[type="file"] {
  display: none;
}
.uploadFile {
  margin-bottom: inherit;
  margin-left: 5px;
  position: relative;
  resize: none;
}
.uploadFile > input[type="file"] {
  cursor: pointer !important;
  display: block;
  filter: alpha(opacity=0);
  opacity: 0;
  position: absolute;
}

.cancelButton {
  margin-right: 25px;
}
.select-container {
  width: 100%;
}

.buttonConatiner {
  justify-content: flex-end !important;
  padding: 1.5rem 0rem;
  border-bottom: none;
}
.attribute-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.attribute-container
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px !important;
}
.attribute-wrapper .css-1iledgx-MuiFormControl-root {
  width: 40% !important;
}

.attribute-container {
  margin-bottom: 0;
}
/* .css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  min-height: 250px !important;
  top: 710px !important;
} */
/* .attribute-set-container */
/* > .css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  top: 880px !important;
  min-width: 210px; */
/* } */

/* .attribute-set-container {
  position: relative;
} */

.collapse-wrapper {
  border: 1px solid #80808057;
  border-radius: 4px;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.button-container {
  background: #d3d3d396;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.submit-button {
  background: #ff8306 !important;
}
.collapse-button-name {
  background: none !important;
  color: #ff8306 !important;
  border: none !important;
  box-shadow: none !important;
}
.add-field-button {
  background-color: #ff8306 !important;
  color: white !important;
  text-transform: capitalize !important;
}

.no-min-width {
  min-width: unset !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-bottom: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.placeholder-select {
  color: #808080;
}
.custom-select:hover .MuiOutlinedInput-notchedOutline {
  border-color: #ff8306;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #ff8306 !important;
  border-width: 2px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: -2px;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 5px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ff8306;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ff8306;
}

input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 7.5px 14px !important;
}
.css-1uvydh2 {
  padding: 7.5px 14px !important;
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.css-1mxz8qt-MuiPaper-root {
  margin-top: 1rem;
}
.css-8atqhb {
  width: 98% !important;
  margin: auto;
}
.css-1chpzqh {
  margin-bottom: 0 !important;
}
.css-levciy-MuiTablePagination-displayedRows {
  margin-bottom: 0;
}
.css-pdct74-MuiTablePagination-selectLabel {
  margin-bottom: 0;
}
.css-1hzveig-MuiToolbar-root {
  padding-left: 22px !important;
}

.nav-item-in-view {
  color: orange !important;
}

.nav-item-in-view > span {
  color: orange !important;
}
.nav-item {
  color: black !important;
}
.css-11yukd5-MuiTabs-indicator {
  display: none !important;
}
.css-1aquho2-MuiTabs-indicator {
  display: none !important;
}
.css-ttwr4n {
  display: none !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #ff8306 !important;
}
.css-1q2h7u5.Mui-selected {
  color: #ff8306 !important;
}
.css-1ujnqem-MuiTabs-root {
  min-height: 15px !important;
}
.css-orq8zk {
  min-height: 15px !important;
}
.lang-fix {
  text-transform: "unset";
  font-size: "12px";
  min-width: "50px";
  padding: "0";
  min-height: "0";
}
.fields-modal {
  width: 90%;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8.5px 14px !important;
}
.css-1x5jdmq {
  padding: 8.5px 14px !important;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}
.css-i44wyl {
  width: 100% !important;
}
.work-opt .css-hlgwow {
  justify-items: center !important;
}
.work-opt .css-1fdsijx-ValueContainer {
  justify-items: center;
}
.sku-filter {
  white-space: nowrap;
}

#react-select-2-listbox {
  text-align: start !important;
}
#react-select-4-listbox {
  text-align: start !important;
}
.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  min-height: 75vh;
}
.css-13xy2my {
  min-height: 75vh;
}
/* .card-container :hover {
  transform: translateY(-5px);
} */
.card-animate:hover {
  -webkit-transform: translateY(calc(-1.5rem / 5));
  transform: translateY(calc(-1.5rem / 5));
  -webkit-box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
  box-shadow: 0 5px 10pxrgba (30, 32, 37, 0.12);
}
.card-animate {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #ff8306 !important;
}
.css-t3ipsp-control {
  border-color: #ff8306 !important;
  box-shadow: none !important;
  outline: none !important;
}
.css-t3ipsp-control:hover {
  border-color: #ff8306 !important;
  box-shadow: none;
}
.css-t3ipsp-control:focus,
.css-t3ipsp-control:focus-visible,
.css-t3ipsp-control:focus-visible {
  outline: none !important;
  border: none !important;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #ff8306 !important;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ff8306;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #ff8306;
}
.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  /* background-color: #0069d9; */
  border-color: #ff8306;
  box-shadow: 0 0 0 0.1rem #ff8306;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #ff8306;
  box-shadow: 0 0 0 0.1rem #ff8306;
}
.text-primary {
  color: #ff8306 !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #ff8306;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #ff8306 !important;
}
.custom-select:focus {
  border-color: #ff8306;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #ff8306;
}
.css-1nmdiq5-menu {
  text-align: start !important;
}
.css-zun73v.Mui-checked,
.css-zun73v.MuiCheckbox-indeterminate {
  color: #ff8306 !important;
}
/* input[type="checkbox"]:checked + label:before {
  background-color: #ff8306 !important;
  background: #ff8306 !important;
}
input[type="checkbox"] {
  visibility: hidden;
}
input[type="checkbox"] + label:before {
  border: 1px solid #333;
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 0.25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
} */
/* input[type="checkbox"]:checked + label::after {
  background-color: #ff8306 !important;
  background: #ff8306 !important;
} */
/* .css-1a4cg4j-MuiButtonBase-root-MuiTab-root {
  font-size: 12px;
  min-width: 50px;
  padding: 12px 6px;
  text-transform: unset;
} */
/* arabic translation */

/* .ms-aside-right-open .ms-aside-right.side-nav {
  transform: translateX(-100%);
}
.ms-aside-right.ms-aside-open.side-nav {
  transform: translateX(0);
}
.body-content,
.ms-has-quickbar .body-content,
.ms-aside-right-open .body-content {
  padding-right: 0;
  padding-left: 0;
}

.ms-aside-overlay.ms-overlay-right {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.3);
}
.ms-aside-right.side-nav {
  transform: translateX(-100%);
}
.ms-aside-right-open .ms-aside-right.side-nav {
  transform: translateX(0);
}
.ms-aside-right-open .body-content {
  padding-left: 255px;
} */

.order-status .css-1hb7zxy-IndicatorsContainer {
  display: none;
}
.order-status .css-13cymwt-control {
  border: none;
  background-color: transparent;
}
/* .order-status  .css-1dimb5e-singleValue{
  
} */
.order-status .css-1jqq78o-placeholder {
  margin-right: 16px;
}
.status-bg-delivered {
  background-color: green;
  color: white;
  border-radius: 10px;
}
.status-bg-delivered .css-1dimb5e-singleValue,
.status-bg-pending .css-1dimb5e-singleValue {
  color: white;
  margin-right: 30px;
}

.status-bg-pending {
  background-color: red;
  border-radius: 10px;
  color: white;
}
.greenText {
  background-color: green;
}

.blueText {
  background-color: blue;
}

.redText {
  background-color: yellow;
}
select {
  /* color: white; */
  border: none;
  /* background: red; */
}

#mySelect select option {
  color: black;
}
.language-dropdown {
  border: none;
  background: none;
  appearance: none;
}
.language-dropdown:hover {
  width: 50%;
  height: 50%;
  color: #878a99;
  background-color: rgba(135, 138, 153, 0.1);
}

/* .oldpass, */
.oldpass {
  top: 8px !important;
}
.oldpass ::before {
  font-size: 20px !important;
}
.reset-container {
  display: contents;
}
@media (max-width: 1200px) {
  .media-query {
    flex-direction: column;
    gap: 1rem;
    margin-left: 0;
  }
  .wrapper {
    margin-right: 0 !important;
  }
  .wrapper-left {
    margin-left: 0 !important;
  }
  .searchbar {
    width: 8% !important;
    margin-left: 3px !important;
  }
  .reset-container {
    display: flex;
  }
}
@media (max-width: 500px) {
  .searchbar {
    width: 40px !important;
  }
}

@media (max-width: 540px) {
  .buttonConatiner {
    padding: 10px 0px;
    margin-top: -20px;
  }
  .saveButton {
    margin-top: 10px !important;
  }
  .submitBotton {
    width: 100%;
  }
}

.form-gender {
  width: 100%;
}

option {
  line-height: 200%;
  padding: 0.5rem !important;
  height: 50px;
}
